import React from 'react';
import 'bootstrap';
import styles from '../styles/components/footer.module.scss';


class Footer extends React.Component {
  render() {
    return (
      <>
      <footer className={`${styles.footer} container-fluid justify-content-center mt-5" role="contentinfo`}>

        <div className={`mt-4`}>
          <ul className='nav justify-content-center'>
            <li className="nav-item active">
              <a className="nav-link" href="/showcase/top-10-payroll/">Top 10 Payroll Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/tools/">Tools</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/payroll-providers/">Payroll Providers</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" href="/faq/">FAQ</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/articles/">Articles</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/earnings-disclaimer/">Earnings Disclaimer</a>
            </li><li className="nav-item">
              <a className="nav-link" href="/privacy-policy/">Privacy</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/terms/">Terms</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact/">Contact</a>
            </li>
          </ul>
          <p className={`copyright text-center mt-3`}>© 2019 PayrollPro.</p>
        </div>
      </footer>
      </>
    );
  }
}

export default Footer;